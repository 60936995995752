import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

import { contactRequest } from "~/utils/Fetch/Funnel";
import { submitTwoFA } from "~/utils/Fetch/Lightning";
import {
  postFunnelAnalytics,
  stepLoaded,
  postEvent,
  resetSession,
} from "./Analytics";
import { trackVirtualPageView } from "~/utils/Fetch/Analytics";
import { parseCookies } from "nookies";
import queryString from "query-string";

// Headers
import AgentHeader from "./Headers/AgentHeader";
import ProgressHeader from "./Headers/ProgressHeader";
// Screens
import AgentUnavailable from "./Screens/AgentUnavailable";
import ClientAlreadyHaveAnAgent from "./Screens/ClientAlreadyHaveAnAgent";
import ClientAlsoSelling from "./Screens/ClientAlsoSelling";
import ClientContactInformation from "./Screens/ClientContactInformation";
import ClientNeeds from "./Screens/ClientNeeds";
import ClientType from "./Screens/ClientType";
import Complete from "./Screens/Complete";
import MissedConnection from "./Screens/MissedConnection";
import PriceRange from "./Screens/PriceRange";
import PropertyAddress from "./Screens/PropertyAddress";
import PropertyType from "./Screens/PropertyType";
import ClientTimeFrame from "./Screens/ClientTimeFrame";
import TwoFA from "./Screens/TwoFA";
import TwoFACode from "./Screens/TwoFACode";
import Error from "./Screens/Error";
import { queryParams as getQueryParams } from "../../../utils/Fetch/Analytics";
import { getLeadUUID, setLeadCreatedUUID } from "../../../utils/UUID";
import {
  HOMEPAGE_FUNNEL,
  HOMEPAGE_TILE_FUNNEL,
  genericFunnels,
} from "~/config/funnel";
import ContactForm from "../Profile/Prime/ContactForm";

/**
 * MessageAgentForm
 *
 * Message Agent Form. A wizard type UX with different headers &
 * screens depending user selections and agent status.
 *
 * @param {*} props
 * @returns
 */
const MessageAgentForm = (props) => {
  const {
    agent,
    onRequestClose,
    onRequestLargeModal,
    funnel,
    isClaimed,
    afterNewScreen,
    useContactForm,
    city,
    state,
    skipLocation,
    noCompetingProposals,
    messageHeader,
    selectedClientType,
    noViewAgents,
  } = props;
  const router = useRouter();

  useEffect(() => {
    if (selectedClientType) {
      nextScreen({ key: "ClientType", value: selectedClientType });
    }
  }, [selectedClientType]);

  // get force_experience query sting
  const forceExperience = router.query.force_experience || null;
  // set claimed and prime
  const [claimed, setClaimed] = useState(() => {
    if (isClaimed) {
      return true;
    }
    switch (forceExperience) {
      case "claimed":
      case "prime":
        return true;
      case "unclaimed":
        return false;
      default:
        return agent.messaging_enabled;
    }
  });
  const [prime, setPrime] = useState(() => {
    switch (forceExperience) {
      case "prime":
        return true;
      case "unclaimed":
        return false;
      default:
        return agent.prime;
    }
  });

  // set states for inputs
  const [clientAlreadyHaveAnAgent, setClientAlreadyHaveAnAgent] = useState();
  const [clientAlsoSelling, setClientAlsoSelling] = useState();
  const [clientEmail, setClientEmail] = useState();
  const [clientFirstName, setClientFirstName] = useState("");
  const [clientLastName, setClientLastName] = useState("");
  const [clientNeeds, setClientNeeds] = useState();
  const [clientPhone, setClientPhone] = useState();
  const [clientPhoneCountry, setClientPhoneCountry] = useState();
  const [clientTimeFrame, setClientTimeFrame] = useState();
  const [clientType, setClientType] = useState(selectedClientType || "");
  const [clientPriceRangeValue, setClientPriceRangeValue] = useState(5);
  const [clientMinPrice, setClientMinPrice] = useState();
  const [clientMaxPrice, setClientMaxPrice] = useState();
  const [competingProposals] = useState(false);
  const [propertyAddress, setPropertyAddress] = useState();
  const [propertyAddressComponents, setPropertyAddressComponents] = useState(
    {}
  );
  const [propertyType, setPropertyType] = useState();
  const [propertyUnitNumber, setPropertyUnitNumber] = useState();

  const [twoFAFirstName, setTwoFAFirstName] = useState("");
  const [twoFALastName, setTwoFALastName] = useState("");
  const [twoFAEmail, setTwoFAEmail] = useState("");
  const [twoFAPhone, setTwoFAPhone] = useState("");
  const [twoFADisplayPhone, setTwoFADisplayPhone] = useState("");
  const [twoFA, setTwoFA] = useState({});

  const [textConsent, setTextConsent] = useState(true);
  const [otherAgents, setOtherAgents] = useState(false);
  // set states for wizard
  const [header, setHeader] = useState(() => {
    if (claimed) return "ProgressHeader";
    return "AgentHeader";
  });
  const [maxStep, setMaxStep] = useState(() => {
    if (claimed) return 6;
    return 5;
  });
  const [screen, setScreen] = useState(() => {
    if (useContactForm) {
      onRequestLargeModal(true);
      return "ContactForm";
    }
    if (claimed) {
      onRequestLargeModal(true);
      return "ClientType";
    }
    return "AgentUnavailable";
  });
  const [screensSeen, setScreenSeen] = useState(new Set());
  const [step, setStep] = useState(1);
  const [consent, setConsent] = useState({});
  const [pageVersion, setPageVersion] = useState(() => {
    return null;
  });

  useEffect(() => {
    stepLoaded(
      { key: screen },
      router,
      agent.rea_agent_status,
      funnel,
      pageVersion
    );
    setScreenSeen((prev) => new Set(prev.add(screen)));
  }, [screen]);

  /**
   * If we are changing the screen instantly we need to use effect to post the analytics.
   */
  useEffect(() => {
    let data = {};

    if (screen === "ClientType") {
      data["clientType"] = clientType;
    } else if (screen === "PropertyType") {
      data["propertyType"] = propertyType;
    } else if (screen === "ClientTimeFrame") {
      data["clientTimeFrame"] = clientTimeFrame;
    } else if (screen === "ClientAlsoSelling") {
      data["clientAlsoSelling"] = clientAlsoSelling;
    } else if (screen === "ClientAlreadyHaveAnAgent") {
      data["clientAlreadyHaveAnAgent"] = clientAlreadyHaveAnAgent;
    }

    if (
      screen === "ClientType" ||
      screen === "PropertyType" ||
      screen === "ClientTimeFrame" ||
      screen === "ClientAlsoSelling" ||
      screen === "ClientAlreadyHaveAnAgent"
    ) {
      postFunnelAnalytics(
        { key: screen, value: data },
        router,
        agent.rea_agent_status,
        funnel,
        false,
        pageVersion
      );
    }
  }, [
    clientType,
    propertyType,
    clientTimeFrame,
    clientAlsoSelling,
    clientAlreadyHaveAnAgent,
  ]);

  useEffect(() => {
    setTwoFAFirstName(clientFirstName);
    setTwoFALastName(clientLastName);
    setTwoFAPhone(clientPhone);
    setTwoFAEmail(clientEmail);
  }, [clientFirstName, clientLastName, clientPhone, clientEmail]);

  /**
   * isBuyer
   *
   *  Quicky findout if buyer
   *
   * @returns
   */
  const isBuyer = () => {
    if (clientType === "buying" || clientType === "buying and selling")
      return true;
    return false;
  };

  /**
   * payload
   *
   * Creates payload for leadgen
   *
   * @returns
   */
  const payload = () => {
    let data = {};

    // We should be using the phone validation, but it was requested we don't for right now.
    let phonenumber = clientPhone;

    data.lead = {
      agent_id: agent.agent_id || null,
      // TODO: This needs to be moved to server side. Leaving here as a reminder.
      force_match: agent?.agent_id ? claimed : false,
      message: clientNeeds || null,
      other_agents: agent?.agent_id ? competingProposals || null : true,
      has_an_agent: clientAlreadyHaveAnAgent || null,
      looking_to_sell: clientAlsoSelling || null,
      apt_number: propertyUnitNumber || null,
      buyer_cities: isBuyer()
        ? `${propertyAddressComponents.city}, ${propertyAddressComponents.state}`
        : null,
      city: propertyAddressComponents.city,
      comments: router.asPath,
      country: propertyAddressComponents.country || "US",
      country_code: "1",
      home_price_max: clientMaxPrice,
      home_price_min: clientMinPrice,
      lat_lng: propertyAddressComponents.lat_lng,
      state: propertyAddressComponents.state,
      street_name: propertyAddressComponents.street_name || null,
      street_number: propertyAddressComponents.street_number || null,
      time_frame: clientTimeFrame,
      type_home: propertyType,
      unit_number: propertyUnitNumber || null,
      zip: propertyAddressComponents.zip || null,
      text_message_consent: textConsent || false,
    };

    if (funnel?.length >= 1) data.lead.funnel = funnel;

    // leads won't submit unless we are passing buyer or seller.
    switch (clientType) {
      case "buying":
        data.lead.lead_type = "buyer";
        break;
      case "selling":
        data.lead.lead_type = "seller";
        break;
      case "buying and selling":
        data.lead.lead_type = "both";
        break;
      default:
        data.lead.lead_type = "other";
        break;
    }

    data.user = {
      name: `${clientFirstName.trim()} ${clientLastName.trim()}`,
      email: clientEmail,
      phonenumber: phonenumber,
    };
    const consent_text_el = document.getElementById("contact-consent-text");
    data.consent = {
      ...consent,
      url: window.location.href,
      source: funnel,
      consent_text_original: twoFADisplayPhone
        ? `By clicking "Text Confirmation Code", you consent, acknowledge and agree to our Terms of Use and Privacy Policy which includes binding arbitration and consent to receive electronic communications, you are providing express written consent for RealEstateAgents.com and our participating agents to contact you at the phone number you provided via calls and/or SMS, MMS using an automated dialing system to provide agent info, even if your number is listed on an internal, corporate, state or federal Do-Not-Call list. You may revoke this consent any time. Consent is not a condition for our service.`
        : funnel.includes(HOMEPAGE_FUNNEL) ||
          funnel.includes(HOMEPAGE_TILE_FUNNEL)
        ? `By clicking "Get Agents" I agree to the Terms of Use and Privacy Policy and I am providing my signature expressly consenting that I am 18 years of age and that RealEstateAgents.com and its participating agents contact me via email.`
        : funnel.includes("message_agent_city")
        ? `By clicking "Send Message to Agent" I agree to the Terms of Use and Privacy Policy and I am providing my signature expressly consenting that I am 18 years of age and that RealEstateAgents.com and its participating agents contact me via email.`
        : `By clicking "Text Confirmation Code", you consent, acknowledge and agree to our Terms of Use and Privacy Policy which includes binding arbitration and consent to receive electronic communications, you are providing express written consent for RealEstateAgents.com and our participating agents to contact you at the phone number you provided via calls and/or SMS, MMS using an automated dialing system to provide agent info, even if your number is listed on an internal, corporate, state or federal Do-Not-Call list. You may revoke this consent any time. Consent is not a condition for our service.`,
      consent_text_shown: consent_text_el
        ? consent_text_el.textContent
        : consent.consent_text_shown,
      email_consent_given: true,
      text_consent_given: true,
      phone_consent_given: true,
    };
    setConsent(data.consent);

    if (!phonenumber) {
      data.new_lead_type = "permissive";
    }

    return data;
  };

  const resubmitPayload = () => {
    let payloadData = payload();
    payloadData.twofa_data = {};

    if (twoFAFirstName?.length > 0 && twoFALastName?.length > 0)
      payloadData.twofa_data.name = `${twoFAFirstName} ${twoFALastName}`;

    if (twoFAEmail?.length > 0) payloadData.twofa_data.email = twoFAEmail;

    if (twoFAPhone?.length > 0) payloadData.twofa_data.phone = twoFAPhone;

    let cookies = parseCookies();

    let uuid = cookies.lead_uuid;
    if (!uuid) {
      uuid = setLeadUUID();
    }

    payloadData.lead.uuid = uuid;
    if (!payloadData.lead.country) {
      payloadData.lead.country = "US";
    }
    let ad_data = {};
    if (process.browser) {
      let queryParams = queryString.parse(getQueryParams());

      if (queryParams && Object.keys(queryParams).length === 0) {
        queryParams = JSON.parse(cookies.utm_params);
      }

      if (!queryParams) {
        queryParams = {};
      }

      ad_data = queryParams;

      ad_data.origin_path = window.location.pathname;
      ad_data.referrer = localStorage.getItem("referrer");
    }
    payloadData.ad_data = ad_data;
    return payloadData;
  };
  /**
   * submit
   *
   * Submits lead and user data to endpoint.
   */
  const submit = () => {
    const payloadData = payload();
    postFunnelAnalytics(
      {
        key: "ClientContactInformation",
        value: {
          name: payloadData.user.name,
          email: payloadData.user.email,
          phonenumber: payloadData.user.phonenumber,
          other_agents: agent?.agent_id ? competingProposals || null : true,
        },
      },
      router,
      agent.rea_agent_status,
      funnel,
      false,
      pageVersion
    );
    const promise = contactRequest(payloadData).then(() => {
      const lead_uuid = getLeadUUID();
      setLeadCreatedUUID(lead_uuid);
      window.localStorage.setItem("lastLeadType", payloadData.lead.lead_type);
      trackVirtualPageView("lead_created", funnel, clientType);
    });
    return promise;
  };

  const resubmit = (twofaCode) => {
    let payloadData = resubmitPayload();
    const promise = submitTwoFA(payloadData, twofaCode);
    return promise;
  };

  const complete = () => {
    postEvent("message_agent_completed", router);
    resetSession();
  };

  const nextStep = () => {
    if (step < maxStep) {
      setStep(step + 1);

      afterNewScreen && afterNewScreen(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
      afterNewScreen && afterNewScreen(step - 1);
    }
  };

  const nextScreen = ({ key, value, twoFA }) => {
    if (key === "ContactForm") {
      setScreen("Complete");
      onRequestLargeModal(false);
      setHeader("ProgressHeader");
      complete();
    } else if (key === "AgentUnavailable" || key === "MissedConnection") {
      onRequestLargeModal(true);
      setHeader("ProgressHeader");
      setScreen("ClientType");
    } else if (key === "ClientType") {
      if (skipLocation) {
        setScreen("PriceRange");
      } else if (
        value === "buying" ||
        value === "selling" ||
        value === "buying and selling"
      ) {
        setScreen("PropertyAddress");
      } else {
        setScreen("ClientNeeds");
      }
    } else if (key === "PropertyAddress") {
      if (clientType === "buying") {
        postFunnelAnalytics(
          {
            key: key,
            value: {
              addressComponents: propertyAddressComponents,
            },
          },
          router,
          agent.rea_agent_status,
          funnel,
          false,
          pageVersion
        );
      } else {
        postFunnelAnalytics(
          {
            key: key,
            value: {
              addressComponents: propertyAddressComponents,
              propertyAptNumber: propertyUnitNumber,
            },
          },
          router,
          agent.rea_agent_status,
          funnel,
          false,
          pageVersion
        );
      }
      setScreen("PriceRange");
    } else if (key === "PriceRange") {
      postFunnelAnalytics(
        {
          key: key,
          value: {
            min: clientMinPrice,
            max: clientMaxPrice,
          },
        },
        router,
        agent.rea_agent_status,
        funnel,
        false,
        pageVersion
      );
      if (clientType === "buying" && !claimed) {
        setScreen("ClientAlsoSelling");
      } else if (claimed) {
        setScreen("ClientAlreadyHaveAnAgent");
      } else {
        setScreen("ClientNeeds");
      }
    } else if (key === "ClientAlreadyHaveAnAgent") {
      if (clientType === "buying") {
        setScreen("ClientAlsoSelling");
      } else {
        setScreen("ClientNeeds");
      }
    } else if (key === "ClientAlsoSelling") {
      setScreen("ClientNeeds");
    } else if (key === "ClientNeeds") {
      postFunnelAnalytics(
        { key: key, value: { clientNeeds: clientNeeds } },
        router,
        agent.rea_agent_status,
        funnel,
        false,
        pageVersion
      );
      setScreen("ClientContactInformation");
    } else if (key === "ClientContactInformation") {
      if (twoFA) {
        setScreen("TwoFA");
      } else {
        setScreen("Complete");
        onRequestLargeModal(false);
        setHeader(() => {});
        complete();
      }
    } else if (key === "TwoFA") {
      setScreen("TwoFACode");
    } else if (key === "TwoFACode") {
      setScreen("Complete");
      onRequestLargeModal(false);
      setHeader(() => {});
      complete();
    }

    if (key === "ClientType") {
      if (value === "buying" && claimed) {
        setMaxStep(7);
      } else if (
        (value === "buying" && !claimed) ||
        ((value === "selling" || value === "buying and selling") && claimed)
      ) {
        setMaxStep(6);
      } else if (
        (value === "selling" || value === "buying and selling") &&
        !claimed
      ) {
        setMaxStep(5);
      } else {
        setMaxStep(3);
      }
    }

    if (
      key !== "AgentUnavailable" &&
      key !== "MissedConnection" &&
      key !== "ClientContactInformation" &&
      key !== "TwoFA"
    ) {
      nextStep();
    }
  };

  const prevScreen = ({ key, value }) => {
    if (key === "AgentUnavailable") {
      setScreen("MissedConnection");
    } else if (key === "MissedConnection") {
      onRequestClose();
    } else if (key === "ClientType") {
      if (claimed) {
        onRequestClose(value, true);
      } else {
        setHeader("AgentHeader");
        setScreen("AgentUnavailable");
        onRequestLargeModal(false);
      }
    } else if (key === "PropertyAddress") {
      setScreen("ClientType");
    } else if (key === "PriceRange") {
      if (skipLocation) {
        setScreen("ClientType");
      } else {
        setScreen("PropertyAddress");
      }
    } else if (key === "ClientAlreadyHaveAnAgent") {
      setScreen("PriceRange");
    } else if (key === "ClientAlsoSelling") {
      if (claimed) {
        setScreen("ClientAlreadyHaveAnAgent");
      } else {
        setScreen("PriceRange");
      }
    } else if (key === "ClientNeeds") {
      if (clientType === "buying") {
        setScreen("ClientAlsoSelling");
      } else if (
        (clientType === "selling" || clientType === "buying and selling") &&
        !claimed
      ) {
        setScreen("PriceRange");
      } else if (
        (clientType === "selling" || clientType === "buying and selling") &&
        claimed
      ) {
        setScreen("ClientAlreadyHaveAnAgent");
      } else {
        setMaxStep(5);
        setScreen("ClientType");
      }
    } else if (key === "ClientContactInformation") {
      setScreen("ClientNeeds");
    } else if (key === "TwoFACode") {
      setScreen("TwoFA");
    }

    if (
      key !== "AgentUnavailable" &&
      key !== "MissedConnection" &&
      key !== "ClientType" &&
      key !== "TwoFACode"
    ) {
      prevStep();
    }
  };

  const displayError = () => {
    setScreen("Error");
    onRequestLargeModal(false);
    setHeader(() => {});
  };
  /**
   * renderHeader
   *
   * Logic for rendering the correct header.
   *
   * @returns
   */
  const renderHeader = () => {
    return (
      <>
        {header === "AgentHeader" && !genericFunnels.includes(funnel) ? (
          <AgentHeader
            agent={agent}
            onRequestClose={onRequestClose}
            useContactForm={useContactForm}
            isPrime={prime}
            isNonPrime={claimed}
            city={city}
            state={state}
          />
        ) : null}
        {header === "ProgressHeader" ? (
          <ProgressHeader
            currentStep={step}
            maxSteps={maxStep}
            onRequestClose={onRequestClose}
            claimed={claimed}
            prime={prime}
            agent={agent}
            funnel={funnel}
            useContactForm={useContactForm}
            isPrime={prime}
            isNonPrime={claimed}
          />
        ) : null}
      </>
    );
  };

  const leadUUID = getLeadUUID(true);

  const clearInlineMessageStoredValues = () => {
    localStorage.removeItem("inline_message_name");
    localStorage.removeItem("inline_message_email");
    localStorage.removeItem("inline_message_phone");
    localStorage.removeItem("inline_message_message");
    localStorage.removeItem("inline_message_country");
  };

  const getInlineMessagesStoredValues = () => {
    return {
      name: localStorage.getItem("inline_message_name"),
      email: localStorage.getItem("inline_message_email"),
      phone: localStorage.getItem("inline_message_phone"),
      message: localStorage.getItem("inline_message_message"),
      country: localStorage.getItem("inline_message_country"),
    };
  };
  /**
   * renderScreen
   *
   * Logic for rendering the correct screen.
   *
   * @returns
   */
  const renderScreen = () => {
    return (
      <>
        {screen === "ContactForm" ? (
          <div style={{ padding: "32px", paddingTop: 16 }}>
            <ContactForm
              agent={agent}
              nextScreen={nextScreen}
              displayError={displayError}
              funnel={funnel}
              clearInlineMessageStoredValues={clearInlineMessageStoredValues}
              getInlineMessagesStoredValues={getInlineMessagesStoredValues}
              setOtherAgents={setOtherAgents}
              isPrime={prime}
              isNonPrime={claimed}
              city={city}
              state={state}
            />
            <div className="MessageAgentForm__screen-control-text-container --client-info">
              <div
                className="MessageAgentForm__screen-control-text --client-info"
                id={"contact-consent-text"}
              >
                By clicking{" "}
                {prime || claimed
                  ? `"Message ${
                      agent.display_name
                        ? agent.display_name.split(" ")[0]
                        : "Agent"
                    }"`
                  : `"Get Connected With Top Agents"`}{" "}
                above, you consent, acknowledge and agree to our{" "}
                <a href="/tos" target="_blank">
                  Terms of Use
                </a>{" "}
                and{" "}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>{" "}
                which includes binding arbitration and consent to receive
                electronic communications, you are providing express written
                consent for{" "}
                <a href="/" target="_blank">
                  RealEstateAgents.com
                </a>{" "}
                and our participating agents to contact you at the phone number
                you provided via calls and/or SMS, MMS using an automated
                dialing system to provide agent info. even if your number is
                listed on an internal, corporate, state or federal Do-Not-Call
                list. You may revoke this consent any time. Consent is not a
                condition for our service.{" "}
              </div>
            </div>
          </div>
        ) : null}
        {screen === "AgentUnavailable" ? (
          <AgentUnavailable nextScreen={nextScreen} prevScreen={prevScreen} />
        ) : null}
        {screen === "MissedConnection" ? (
          <MissedConnection
            agent={agent}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
          />
        ) : null}
        {screen === "ClientType" ? (
          <ClientType
            funnel={funnel}
            clientType={clientType}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
            noViewAgents={noViewAgents}
            setClientType={setClientType}
            leadUUID={leadUUID}
            messageHeader={messageHeader}
          />
        ) : null}
        {screen === "PropertyType" ? (
          <PropertyType
            clientType={clientType}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
            propertyType={propertyType}
            setPropertyType={setPropertyType}
            leadUUID={leadUUID}
          />
        ) : null}
        {screen === "ClientTimeFrame" ? (
          <ClientTimeFrame
            clientTimeFrame={clientTimeFrame}
            clientType={clientType}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
            setClientTimeFrame={setClientTimeFrame}
            leadUUID={leadUUID}
          />
        ) : null}
        {screen === "PropertyAddress" ? (
          <PropertyAddress
            claimed={claimed}
            clientType={clientType}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
            propertyAddress={propertyAddress}
            propertyAddressComponents={propertyAddressComponents}
            propertyUnitNumber={propertyUnitNumber}
            setPropertyAddress={setPropertyAddress}
            setPropertyAddressComponents={setPropertyAddressComponents}
            setPropertyUnitNumber={setPropertyUnitNumber}
            leadUUID={leadUUID}
          />
        ) : null}
        {screen === "PriceRange" ? (
          <PriceRange
            clientType={clientType}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
            claimed={claimed}
            setClientMinPrice={setClientMinPrice}
            setClientMaxPrice={setClientMaxPrice}
            clientPriceRangeValue={clientPriceRangeValue}
            setClientPriceRangeValue={setClientPriceRangeValue}
            leadUUID={leadUUID}
          />
        ) : null}
        {screen === "ClientAlsoSelling" ? (
          <ClientAlsoSelling
            clientAlsoSelling={clientAlsoSelling}
            setClientAlsoSelling={setClientAlsoSelling}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
            leadUUID={leadUUID}
          />
        ) : null}
        {screen === "ClientAlreadyHaveAnAgent" ? (
          <ClientAlreadyHaveAnAgent
            clientAlreadyHaveAnAgent={clientAlreadyHaveAnAgent}
            setClientAlreadyHaveAnAgent={setClientAlreadyHaveAnAgent}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
            leadUUID={leadUUID}
          />
        ) : null}
        {screen === "ClientNeeds" ? (
          <ClientNeeds
            placeholderProp={
              genericFunnels.includes(funnel) &&
              "Enter any details about your real estate needs"
            }
            headingProp={
              genericFunnels.includes(funnel) &&
              "Are there any other details you'd like to share?"
            }
            clientNeeds={clientNeeds}
            setClientNeeds={setClientNeeds}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
            claimed={claimed}
            funnel={funnel}
            agent={agent}
            clientType={clientType}
            screensSeen={screensSeen}
            notRequired={genericFunnels.includes(funnel)}
            leadUUID={leadUUID}
          />
        ) : null}
        {screen === "ClientContactInformation" ? (
          <ClientContactInformation
            clientEmail={clientEmail}
            ctaProp={genericFunnels.includes(funnel) && "Get Agents"}
            headingProp={
              genericFunnels.includes(funnel) &&
              "Last step! Now just add a few contact details"
            }
            noCompetingProposals={noCompetingProposals}
            clientFirstName={clientFirstName}
            clientLastName={clientLastName}
            clientPhone={clientPhone}
            clientPhoneCountry={clientPhoneCountry}
            setClientEmail={setClientEmail}
            setClientFirstName={setClientFirstName}
            setClientLastName={setClientLastName}
            setClientPhone={setClientPhone}
            setClientPhoneCountry={setClientPhoneCountry}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
            funnel={funnel}
            claimed={claimed}
            competingProposals={competingProposals}
            agent={agent}
            screensSeen={screensSeen}
            submit={submit}
            displayError={displayError}
            setTwoFA={setTwoFA}
            leadUUID={leadUUID}
            textConsent={textConsent}
            setTextConsent={setTextConsent}
          />
        ) : null}
        {screen === "Complete" ? (
          <Complete
            agent={agent}
            onRequestClose={onRequestClose}
            claimed={claimed}
            funnel={funnel}
            competingProposals={competingProposals}
            useContactForm={useContactForm}
            otherAgents={otherAgents}
            isPrime={prime}
            isNonPrime={claimed}
          />
        ) : null}
        {screen === "TwoFA" && (
          <TwoFA
            setTwoFAFirstName={setTwoFAFirstName}
            setTwoFALastName={setTwoFALastName}
            setTwoFAEmail={setTwoFAEmail}
            setTwoFAPhone={setTwoFAPhone}
            setTwoFADisplayPhone={setTwoFADisplayPhone}
            twoFAFirstName={twoFAFirstName}
            twoFALastName={twoFALastName}
            twoFAEmail={twoFAEmail}
            twoFAPhone={twoFAPhone}
            clientPhoneCountry={clientPhoneCountry}
            setClientPhoneCountry={setClientPhoneCountry}
            nextScreen={nextScreen}
            screensSeen={screensSeen}
            agent={agent}
            twoFA={twoFA}
            funnel={funnel}
            setConsent={setConsent}
            consent={consent}
          />
        )}
        {screen === "TwoFACode" && (
          <TwoFACode
            twoFAPhone={twoFAPhone}
            nextScreen={nextScreen}
            prevScreen={prevScreen}
            screensSeen={screensSeen}
            resubmit={resubmit}
            displayError={displayError}
            twoFADisplayPhone={twoFADisplayPhone}
            funnel={funnel}
          />
        )}
        {screen === "Error" && <Error onRequestClose={onRequestClose} />}
      </>
    );
  };

  return (
    <div
      className={`MessageAgentForm ${
        genericFunnels.includes(funnel) ? "--funnel" : ""
      }`}
    >
      {header ? renderHeader() : null}
      {screen ? renderScreen() : null}
    </div>
  );
};

MessageAgentForm.propTypes = {
  agent: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onRequestLargeModal: PropTypes.func.isRequired,
  funnel: PropTypes.string,
};

MessageAgentForm.defaultProps = {
  agent: {},
  onRequestClose: () => {},
  onRequestLargeModal: () => {},
  funnel: "",
};

export default MessageAgentForm;
